const en = {
  'noscript_warning': 'You need to enable JavaScript to run this app.',
  'company_name': '"' + process.env.REACT_APP_GLOBAL_COMPANY_NAME + '" LLC',
  'company_address': '111204, Republic of Uzbekistan, Tashkent region, Kibray district, A. Navoi MFY, A. Navoi street, 4',
  'attribute-photo': 'Photo',
  'attribute-username': 'Username',
  'attribute-first_name': 'First name',
  'attribute-last_name': 'Last name',
  'attribute-middle_name': 'Middle name',
  'attribute-email': 'Email',
  'attribute-date_of_birth': 'Date of birth',
  'attribute-city': 'City',
  'attribute-address': 'Address',
  'attribute-phone': 'Phone',
  'attribute-role': 'Role',
  'attribute-password': 'Password',
  'attribute-message': 'Message',
  'attribute-thread': 'Thread',
  'attribute-subject': 'Subject',
  'attribute-content': 'Content',
  'attribute-title': 'Title',
  'attribute-hour': 'Hour',
  'attribute-minute': 'Minute',
  'attribute-second': 'Second',
  'attribute-name': 'Username',
  'attribute-slug': 'Slug',
  'attribute-description': 'Description',
  'attribute-path': 'Path',
  'attribute-title.uz': 'Title (uz)',
  'attribute-title.ru': 'Title (ru)',
  'attribute-title.en': 'Title (en)',
  'attribute-slug.uz': 'Slug (uz)',
  'attribute-slug.ru': 'Slug (ru)',
  'attribute-slug.en': 'Slug (en)',
  'attribute-path.*': 'Path',
  'attribute-sorting_order': 'Sorting order',
  'attribute-image': 'Image',
  'attribute-uploadFiles.*': 'uploaded file',
  'attribute-locale': 'Language',
  'attribute-publish_date': 'Publish date',
  'attribute-status': 'Status',
  'attribute-template': 'Template',
  'attribute-post_type': 'Post type',
  'attribute-published_date': 'Published date',
  'attribute-page_template': 'Page template',
  'attribute-thumbnail': 'Thumbnail',
  'attribute-key': 'Key',
  'attribute-value': 'Value',
  'attribute-key.*': 'Key',
  'attribute-files.*': 'Media file',
  'attribute-is_public': 'Is publicly queryable on frontend',
  'attribute-logotype': 'Logotype',
  'attribute-avatar': 'Avatar',
  'attribute-occupation': 'Occupation',
  
  'brand_definition': 'Online booking for services | YouBook.Uz',
  'ok': 'OK',
  'cancel': 'Cancel',
  'back': 'Back',
  'create': 'Create',
  'edit': 'Edit',
  'update': 'Update',
  'save': 'Save',
  'delete': 'Delete',
  'select': 'Select',
  'resend': 'Resend',
  'saved': 'Saved successfully',
  'current_password_incorrect': 'Current password is incorrect',
  'password_changed': 'Password changed successfully',
  'all_rights_reserved': 'All rights reserved',
  'alternative_button_url': 'If you’re having trouble clicking the ":action_text" button, copy and paste the URL below into your web browser',
  'user_avatar_removed': 'Your avatar has been removed successfully',
  'yes': 'Yes',
  'no': 'No',
  'for_example': 'For example',
  'exempli_gratia': 'e.g.',
  'optional': 'optional',
  'next': 'Next',
  'for': 'for',
  // ==========
  'or': 'or',
  'toggle_navigation': 'Toggle Navigation',
  'login': 'Login',
  'register': 'Signup',
  'register_phone': 'Signup with mobile number',
  'logout': 'Logout',
  'e_mail_address': 'E-mail Address',
  'password': 'Password',
  'remember_me': 'Remember me',
  'forgot_your_password': 'Forgot Your Password?',
  'name': 'Name',
  'confirm_password': 'Confirm Password',
  'reset_password': 'Reset Password',
  'send_password_reset_link': 'Send Password Reset Link',
  'register_with_mobile_phone_number': 'You don\'t have an e-mail address? {get_registered} with mobile phone number',
  'get_registered': 'Signup',
  'register_with_email': 'You don\'t have mobile phone number? {get_registered} with an e-mail',
  'mobile_phone_number': 'Mobile Phone Number',
  'username': 'Username',
  'you_registered_successfully': 'You have successfully signed up in the system',
  'one_id': 'Unified Identification System',
  'are_you_sure': 'Are you sure?',
  'avatar': 'Avatar',
  'my_account': 'My Account',
  'phone': 'Phone number',
  'current_password': 'Current password',
  'password_change_wanted': 'If you want to change your password, insert your current password and new password together with confirmation',
  'authentication_successful': 'You\'ve been authenticated successfully!',
  'media_explorer': 'Media explorer',
  'upload': 'Upload',
  'media_list': 'Media list',
  'preview': 'Preview',
  'set': 'Set',
  'close': 'Close',
  'payload_too_large': 'Payload too large!',
  'add_new': 'Add new',
  'dashboard': 'Dashboard',
  'personal_information': 'Personal information',
  'generic_ajax_error': 'Something went wrong! If the problem persists, please, let the administration to know.',
  'please_select_file': 'Please select an image file to upload',
  'or_login_with_these': 'or login with these',
  'pages-home': 'Home',
  'pages-about': 'About Service',
  'pages-pricing': 'Pricing',
  'pages-contact': 'Contact',
  'pages-faq': 'FAQ',
  'pages-privacy': 'Privacy Policy',
  'pages-tos': 'Terms of Service',
  'pages-service-categories': 'Service Areas',
  'pages-help': 'Help',
  'let_us_be_social': 'Join us',
  'we_are_on_the_networks': 'We\'re on social networks',
  'acceptance_of_tos': 'Using this application means user\'s full acceptance of the {tos}.',
  'tos_agree_text': 'terms of service',
  'no_internet_connection': 'There was an error with the network, please try again',
  'you_are_not_authenticated_please_login': 'You are not authenticated, please login',
  'no_sufficient_rights_to_perform_action': 'You do not have sufficient rights to perform this action',
  'errorDuringSignInWithPhoneNumber': 'Error, please, try again',
  'errorWhileCheckingTheVerificationCode': 'Error while checking the verification code, please, try again',
  'pleaseEnterTheCodeYouWillReceiveInAnSms': 'Please, enter the code you will receive in an SMS:',
  'pleaseEnterAValidPhoneNumber': 'Please, enter a valid phone number!',
  'email_verified': 'Your email address has been verified!',
  'verification_link_sent': 'A fresh verification link has been sent to your email address.',
  'verify_your_email': 'Verify your email address. Before proceeding, please check your email for a verification link. If you did not receive the email',
  'click_here_to_request_another': 'click here to request another',
  'start': 'Start',

  'my_organizations': 'My organizations',
  'title': 'Name',
  'address': 'Address',
  'contacts': 'Contacts',
  'add_new_organization': 'Add new',
  'street_address': 'Block, street address, house number, flat number',
  'postcode': 'Postcode',
  'email': 'Email',
  'website': 'Website',
  'telegram': 'Telegram',
  'latitude': 'Latitude',
  'longitude': 'Longitude',
  'location': 'Location (region, district, city or town)',
  'organization_title': 'Title of company, business entity, foundation, etc.',
  'organization_created': 'Organization has been created successfully!',
  'organization_edited': 'Organization details have been edited successfully!',
  'branches': 'Branches',
  'branch': 'Branch',
  'branch_title': 'Title of branch',
  'add_new_branch': 'Add new',
  'branch_created': 'Branch has been created successfully!',
  'branch_edited': 'Branch details have been edited successfully!',
  'set_logotype': 'Set logotype',
  'set_logotype_success_text': 'Logotype has been set successfully!',
  'delete_logotype_success_text': 'Logotype has been deleted successfully!',
  'settings': 'Settings',
  'n_seconds': '{1} :value second|[2,*] :value seconds',
  'n_minutes': '{1} :value minute|[2,*] :value minutes',
  'n_hours': '{1} :value hour|[2,*] :value hours',
  'n_days': '{1} :value day|[2,*] :value days',
  'n_weeks': '{1} :value week|[2,*] :value weeks',
  'n_months': '{1} :value month|[2,*] :value months',
  'n_years': '{1} :value year|[2,*] :value years',
  'min_time_till_event': 'Minimum allowed time before service booking',
  'max_time_till_event': 'Maximum allowed time before service booking',
  'minimum_allowed_time_duration': 'Minimum allowed service time (event)',
  'service_categories': 'Service categories',
  'organization_type': 'Organization type',
  'organizational_and_legal_form': 'Organizational and legal form',
  'service': 'Service',
  'services': 'Services',
  'add_new_service': 'Add new',
  'service_provider': 'Service provider',
  'service_providers': 'Service providers',
  'description': 'Description',
  'display_on_booking_page': 'Display on booking page',
  'hide_duration_on_booking_page': 'Hide duration on booking page',
  'duration_hours': 'Duration hours',
  'duration_minutes': 'Duration minutes',
  'buffer_hours_after': 'Buffer hours after',
  'buffer_minutes_after': 'Buffer minutes after',
  'regular_price': 'Regular price',
  'sale_percentage': 'Sale percentage (%)',
  'sale_price': 'Sale price',
  'service_created': 'Service has been created successfully!',
  'service_edited': 'Service details have been edited successfully!',
  'set_thumbnail': 'Set thumbnail',
  'set_thumbnail_success_text': 'Thumbnail has been set successfully!',
  'delete_thumbnail_success_text': 'Thumbnail has been deleted successfully!',
  'full_name': 'Fullname',
  'set_avatar': 'Set photo',
  'set_avatar_success_text': 'Photo has been set successfully!',
  'delete_avatar_success_text': 'Photo has been deleted successfully!',
  'add_new_service_provider': 'Add new',
  'service_provider_created': 'Service provider has been created successfully!',
  'service_provider_edited': 'Service provider details have been edited successfully!',
  'max_num_clients': 'Maximum number of clients served at the same time',
  'set_service_categories': 'Choose service fields',
  'service_categories_select_warning': 'Warning! You will not be able to change your service fields once you save, so please be attentive.',
  'select_provider_services': 'Select the services that are provided by this employee',
  'is_active_organization': 'Is operating',
  'is_active_branch': 'Is operating',
  'service_category': 'Service category',
  'select_service_providers': 'Select service providers',
  'provided_services': 'Provided services',
  'service_provider_information': 'Information about this employee',
  'service_information': 'Information about this service',
  'schedule_settings': 'Work schedule',
  'weekly_schedule': 'Schedule for the week',
  'special_schedule': 'Special days schedule',
  'unavailability_schedule': 'Unavailable times',
  'remove_schedule_interval': 'Remove interval',
  'monday': 'Monday',
  'tuesday': 'Tuesday',
  'wednesday': 'Wednesday',
  'thursday': 'Thursday',
  'friday': 'Friday',
  'saturday': 'Saturday',
  'sunday': 'Sunday',
  'monday_short': 'Mon',
  'tuesday_short': 'Tue',
  'wednesday_short': 'Wed',
  'thursday_short': 'Thu',
  'friday_short': 'Fri',
  'saturday_short': 'Sat',
  'sunday_short': 'Sun',
  'working_time': 'Working time',
  'available_time': 'Available time',
  'non_working_time': 'Non working time',
  'schedule_edited': 'Schedule has been edited successfully!',
  'minor_ordinals_masculine_1': 'First',
  'minor_ordinals_masculine_2': 'Second',
  'minor_ordinals_masculine_3': 'Third',
  'minor_ordinals_masculine_4': 'Fourth',
  'minor_ordinals_masculine_5': 'Fifth',
  'minor_ordinals_masculine_last': 'Last',
  'minor_ordinals_feminine_1': 'First',
  'minor_ordinals_feminine_2': 'Second',
  'minor_ordinals_feminine_3': 'Third',
  'minor_ordinals_feminine_4': 'Fourth',
  'minor_ordinals_feminine_5': 'Fifth',
  'minor_ordinals_feminine_last': 'Last',
  'minor_ordinals_neutral_1': 'First',
  'minor_ordinals_neutral_2': 'Second',
  'minor_ordinals_neutral_3': 'Third',
  'minor_ordinals_neutral_4': 'Fourth',
  'minor_ordinals_neutral_5': 'Fifth',
  'minor_ordinals_neutral_last': 'Last',
  'if_exists': 'if exists',
  'of_month': 'of month',
  'select_branch': 'Select a branch',
  'select_date_to_set_special_schedule': 'Select the date you want to set special schedule for',
  'special_schedule_for': 'Special schedule for',
  'is_working_day': 'Is working day',
  'appointment': 'Appointment',
  'about_us': 'About us',
  'staff_members': 'Staff members',
  'booking_steps_branch': 'Branch',
  'booking_steps_service': 'Service',
  'booking_steps_provider': 'Provider',
  'booking_steps_date': 'Date',
  'booking_steps_your_info': 'Your info',
  'booking_steps_client': 'Client',
  'booking_steps_confirm': 'Confirm',
  'booking_steps_client_info': 'Client info',
  'booking_steps_place': 'Place',
  'booking_steps_inventory': 'Inventory',
  'organization_description': 'Organization description, information about this organization',
  'branch_description': 'Branch description, information about this branch',
  'until_you_select_special_time_and_press_save_button': 'Until you press "Save" button after selecting special date and set special schedule for it no changes are saved. Special date schedule has more priority than other days.',
  'select_service': 'Select a service',
  'you_can_select_multiple_services': 'You can select multiple services',
  'shortened_time_labels_h': 'h.',
  'shortened_time_labels_m': 'm.',
  'shortened_time_labels_s': 's.',
  'cost': 'Cost',
  'duration': 'Duration',
  'free_of_charge': 'Free of charge',
  'unknown': 'Unknown',
  'select_service_provider': 'Select a service provider',
  'select_datetime': 'Select date and time',
  'first_name': 'First name',
  'last_name': 'Last name',
  'middle_name': 'Middle name',
  'mandatory_fields': 'Fields which are mandatory to fill in',
  'client': 'Client',
  'confirm_booking_details': 'Confirm details',
  'date': 'Date',
  'starts_at': 'Starts at',
  'confirm_booking': 'Confirm',
  'add_another_service': 'Add another service',
  'branch_schedule_may_differ_from_organization': 'Branch schedule may differ from that of the main office',
  'provider_services': 'The services that are provided by this employee',
  'higher_priority_than_4_th_or_5_th_day': 'Higher priority than 4th or 5th week day of month',
  'select_another_date_and_time': 'The date and time you have selected intersects with already selected services\' times. Please, select another date and time.',
  'select_other_available_date_and_time': 'The date and time you have selected intersects with non-working time. Please, select another date and time.',
  'unavailable_date_and_time': 'The date and time you have selected is unavailable. Please, select another date and time.',
  'select_other_date_and_time_slots': 'The date and time you have selected intersects with non-working time. Please, select another date and time.',
  'special_schedule_applies_to_service_providers': 'Special schedule applies to service providers of this branch as well.',
  'working_time_in_other_branches': 'Working time in other branches',
  'authorize_suggest_prompt_text': 'Register or log into your personal account to not fill out the fields every time',
  'service_provider_not_available': 'Service provider is not available for the selected time, it seems that someone has already booked. Please select another time or service provider',
  'select_at_least_one_service_to_book': 'Please, select at least one service to book',
  'occupied': 'occupied',
  'working_time_slot': 'working time slot',
  'partly_available_service_provider_time_slot': 'partly available time slot (service provider can accept new bookings for the same service)',
  'non_working_or_booked_time_slot': 'non working or booked time slot',
  'select_other_slot_or_the_beginning_of_booked_time_slot': 'The date and time you have selected intersects with already booked slots. Please, select another date and time, or the beginning of booked time slot for the same service.',
  'select_other_slot': 'The date and time you have selected intersects with already booked slots. Please, select another date and time.',
  'my_bookings_and_appointments': 'Appointments',
  'my_restaurant_bookings': 'Seat reservation',
  'my_rentals': 'Rentals',
  'my_events_and_entertainment': 'Events and entertainment',
  'booking_accepted': 'Accepted!',
  'your_booking_accepted_text': 'Your request has been received. If you have any questions or comments, you can contact the administration of the organization through the contact information. Thank you!',
  'to_continue_press_close_button': 'To continue press close button.',
  'select_your_organization': 'Select your organization',
  'code': 'Code',
  'booking_code': 'Booking code',
  'created_at': 'Created at',
  'status': 'Status',
  'actions': 'Actions',
  'active': 'Active',
  'inactive': 'Inactive',
  'new': 'New',
  'pending': 'Pending',
  'approved': 'Approved',
  'cancelled': 'Cancelled',
  'expired': 'Expired',
  'view': 'view',
  'show_filters': 'Show filters',
  'hide_filters': 'Hide filters',
  'apply': 'Apply',
  'clear': 'Clear',
  'created_at_from': 'Created date from',
  'created_at_to': 'Created date to',
  'service_at_from': 'Service time from',
  'service_at_to': 'Service time to',
  'please_wait': 'Please wait',
  'bookings_and_appointments': 'Appointments',
  'select_please': 'Please, select',
  'booking_types_select_appointments': 'Bookings and appointments',
  'booking_types_select_services': 'Bookings and appointments',
  'booking_types_select_rentals': 'Rentals',
  'booking_types_select_restaurants': 'Reservations',
  'booking_types_select_reservations': 'Reservations',
  'booking_types_select_events': 'Booking for events',
  'booking_channels_for_clients': 'Bookings and appointments methods for clients',
  'booking_channels_by_phone': 'By phone',
  'booking_channels_on_shopfront': 'On the organization webpage',
  'bookings_performed_hints_by_phone': 'Accepted by the administrator on the phone, this method is always available',
  'bookings_performed_hints_on_shopfront': 'Made independently by customers on the web page of the organization',
  'notification_channels_for_clients': 'Methods to send notifications to clients',
  'notification_channels_by_email': 'By email',
  'notification_channels_by_telegram': 'By Telegram',
  'notification_channels_by_sms': 'By SMS',
  'notifications_performed_hints_by_email': 'The message will be sent to the client\'s email address (if any), this method is always enabled',
  'notifications_performed_hints_by_telegram': 'The message is sent to the client\'s Telegram account (if any), this method is always enabled',
  'notifications_performed_hints_by_sms': 'The message is sent to the client\'s mobile phone number, this method can be turned on or off',
  'make_a_call': 'make a call',
  'not_accepted_on_shopfront_page': 'This organization does not accept bookings, appointments and queries for rentals from organization\'s web page. You can contact the administration using the methods that are more convenient for you. Thank you!',
  'you_can_use_the_following_contact_details': 'You can use the following contact details',
  'my_administrators': 'Administrators',
  'my_administrator_deleted': 'Administrator was deleted',
  'administrator_created_successfully': 'Administrator created successfully',
  'error_while_creating_administrator': 'An error occurred while creating administrator',
  'organizations_or_branches': 'Organizations (branches)',
  'set_administrator_branches': 'Indicate your organizations (branches) which this operator will serve',
  'search_organization': 'Search for an organization',
  'go_to_organization_page': 'Proceed',
  'nothing_under_this_service_category': 'No organizations exist under \':service_category\' service category',
  'try_searching_in_other_categories': 'Please, try searching in <a href=\':categories_url\'>other categories</a>',
  'doesnt_supply_services_yet': 'This business entity does not supply services yet',
  'try_searching_for_other_organizations': 'Try searching for others',
  'for_other_organizations': 'other organizations',
  'no_organization_found_in_search': 'No results were found for your search query \':search_token\'',
  'found_organizations_for_your_search': 'Results for your search query \':search_token\'',
  'organization_search_hint': 'Organization name, located city or district, address',
  'whereabouts': 'Location',
  'search': 'search',
  'no_organizations_found': 'No organizations found',
  'minute': 'm.',
  'calendar': 'Calendar',
  'list': 'List',
  'details': 'Details',
  'passphrase': 'Passphrase',
  'approve_booking': 'Approve',
  'cancel_booking': 'Cancel',

  'rentals': 'Rentals',
  'rental_services': 'Rental services',
  'inventory': 'Inventory',
  'table_place_booking': 'Table, place booking',
  'tables_rooms': 'Tables, rooms',
  'events_and_entertainment': 'Events and entertainment',
  'tickets_seats_rooms': 'Tickets, seats, rooms',
  'serves_which_branches': 'Branches served',
  'occupation': 'Occupation',
  'make_an_appointment': 'Make an appointment',
  'rent': 'Rent',
  'make_a_reservation': 'Make a reservation',
  'sign_up_for_event': 'Sign up for event',
  'reservables': 'Tables, rooms',
  'short_information': 'Short information',
  'any_short_information': 'Any short information',
  'reservable_type_table': 'Table',
  'reservable_type_room': 'Room',
  'title_or_number': 'Title or number (№)',
  'select_what_to_create': 'Select what to create',
  'please_select_an_image_file': 'Please select an image!',
  'room_type': 'Room type',
  'room_type_room': 'Room',
  'room_type_hall': 'Hall',
  'room_type_balcony': 'Balcony',
  'rentables': 'Rentables',
  'sku': 'SKU',
  'inventory_availability_determined_by_sku': 'The availability of inventories with the same name is determined by the SKU',
  'service_type_service': 'Service',
  'service_type_appointment': 'Appointment',
  'service_type_training': 'Training',
  'service_type_tutoring': 'Tutoring',
  'service_type_other_service': 'Other service type',
  'type_of_tariffication': 'Type of tariffication',
  'tariffication_type_hourly': 'Hourly',
  'tariffication_type_daily': 'Daily',
  'tariffication_type_weekly': 'Weekly',
  'tariffication_type_monthly': 'Monthly',
  'rentable_type_book': 'Book',
  'rentable_type_real_estate': 'Real estate',
  'rentable_type_room': 'Room',
  'rentable_type_heavy_machinery': 'Heavy machinery',
  'rentable_type_construction_equipment': 'Construction equipment',
  'rentable_type_car': 'Car',
  'rentable_type_bicycle': 'Bicycle',
  'rentable_type_sport_equipment': 'Sport equipment',
  'rentable_type_disk': 'Disk',
  'rentable_type_other_inventory': 'Other inventory',
  'events': 'Events',
  'event_name': 'Name of event or other happening',
  'event_information': 'Information about this event',
  'stock_count': 'Stock count',
  'event_datetime_from': 'Event datetime from',
  'event_datetime_to': 'Event datetime to',
  'select_branches_where_this_service_provided': 'Select the branch(es) where this service is provided',
  'select_branch_where_this_inventory_belongs_to': 'Select the branch where this inventory belongs to',
  'venue_arrangement': 'Venue arrangement',
  'venues': 'Venues',
  'create_one_by_one': 'Create one by one',
  'generate_in_bulk': 'Generate in bulk',
  'generate': 'Generate',
  'venue_type_ticket': 'Ticket',
  'venue_type_seat': 'Seat',
  'venue_type_room': 'Room',
  'venue_number_or_title': 'Number (№) or title',
  'row_number': 'Row number',
  'seat_number': 'Seat number',
  'row': 'Row',
  'seat': 'Seat',
  'page_not_found': 'The page you were looking for is not found',
  'places': 'Places',
  'subtype': 'Subject',
  'schedule': 'Schedule',
  'lasting': 'Lasting',
  'category': 'Category',
  'client_info': 'Client info',
  'select_place': 'Select place',
  'indicate_how_long_will_stay': 'Please, indicate how long you will stay (duration in minutes)',
  'hours': 'h.',
  'max_stay_duration_is_until_workday_end': 'the maximum stay duration can be until the end of the working day',
  'place': 'Place',
  'time': 'Time',
  'select_inventory': 'Select inventory',
  'you_can_rent_only_on_one_tariffication_basis': 'You can rent only on one tariffication type basis at the same time',
  'indicate_how_long_will_rent': 'Please, indicate for how long you will rent',
  'renting_duration_hourly': 'duration in hours',
  'renting_duration_daily': 'duration in days',
  'renting_duration_weekly': 'duration in weeks',
  'renting_duration_monthly': 'duration in months',
  'renting_period_from': 'Renting period from',
  'renting_period_to': 'Renting period to',
  'bookings': 'Bookings',
  'view_type': 'View type',
  'datetime_from': 'Time from',
  'datetime_to': 'Time to',
  'service_s': 'Service(s)',
  'bookables_total': 'Total',
  'text_copied': 'Text copied!',
  'cost_depends_on_order': 'Cost depends on the order',
  'schedule_was_not_set': 'The schedule is not set',
  'service_datetime_from': 'Service time from',
  'service_datetime_to': 'Service time to',
  'rental_return_time_is_non_working': 'Return time of inventory is non working, please, either select another time or contact the personnel of the organization.',
  'telegram_username': 'Telegram username (without @ sign)',
  'telegram_username_hint': 'it\'s possible to receive notifications to this account if access is given to our <a target="_blank" href="' + process.env.REACT_APP_TELEGRAM_BOT_URL + '">bot</a>, for this it\'s enough to type the command <a target="_blank" " href="' + process.env.REACT_APP_TELEGRAM_BOT_DESCRIPTION_URL + '">/link</a> inside the bot',
  'booking_information': 'Booking details',
  'client_notes': 'Client notes',
  'admin_notes': 'Operator notes',
  'cancellation_reason': 'Cancellation reason',
  'notes': 'Notes',
  'dont_forget_to_leave_notes': 'If applicable, please provide a reason for the cancellation in the "Notes" section.',
  'inputs_are_saved_automatically': 'entered texts are saved automatically',
  'client_preferred_language': 'Preferred language for conversation',
  'notifications': 'Notifications',
  'message': 'Message',
  'read_at': 'Read at',
  'you_dont_have_notifications_yet': 'You don\'t have any notifications yet',
  'got_impressed_to_our_features': 'Got impressed to our app\'s features?',
  'got_impressed_get_register': 'Then don\'t waste your time and start your booking business with us right now',
  'get_to_know': 'Get to know',
  'get_started_now': 'Get Started Now',
  'how_it_works': 'How It Works',
  'all_service_categories': 'View all',
  'our_service_categories': 'Our service areas',
  'choose_service_category_fitting_your_business': 'Choose service category that fits your business activity',
  'forbidden': 'Forbidden',
  'go_back_home': 'Go to Homepage',
  'make_a_booking': 'Book',
  'faq': 'Frequently asked questions',
  'notifications_will_be_sent': 'notifications will be sent',
  'timeslots_shifting_and_time_gaps_in_between_warning': 'Try selecting time slots as close to the latest booking or to the closest working hour as possible, when appropriate. Not trying to avoid causing possible time gaps in-between that are impossible to book may cause the organization administrators to cancel your booking.',
  'help_center': 'Help Center',
  'enter_some_keywords': 'Enter some keywords',
  'nothing_found': 'Nothing found',
  'view_booking_details': 'You can view the details by this link',
  'can_you_return_inventory_until_the_end_of_the_working_hours': 'The exact return time of the inventory is non-working, unfortunately. Would you be able to return the inventory until the end of the working hours? Say, 5 minutes before?',
  'attention': 'Attention!',
  'place_type': 'Place type',
  'max_time_till_event_text': 'Maximum allowed time before service booking is',
  'too_many_requests': 'You have sent too many requests, please, try again a little later!',
  'balance': 'Account Balance',
  'did_not_find_what_you_were_looking_for': 'Did not find what you were looking for?',
  'there_is_an_update': 'A new version of the application is available!',
  'refresh-now': 'Refresh',
  'not-now': 'Not now',
  'we_use_cookies_notification': 'We use cookie files to ensure performance and improve the quality of service.',
  'i_agree': 'I agree',
  'menu': 'Menu',
  'paid_sms_service_warning': 'sms service is paid, single price for each sms in the amount of ' + process.env.REACT_APP_SMS_COST + ' UZS, sms messages are sent to customers when bookings are made, approved, cancelled, and when the organization\'s, branch\'s and service provider\'s working schedule is changed',
  'go_to_booking_page': 'go to booking page',
  'reset_password_with_email': 'Reset password by email',
  'reset_password_with_otp': 'Reset password by code',
  'confirm_mobile_number': 'Confirm mobile number',
  'email_must_be_verified_to_get_notifications': 'Your email address must be verified in order you to be able to get notifications, including a password reset notification in case if you forget.',
  'add_email_or_telegram_account_warning': 'You do not have email or telegram account set. Please, consider adding both or either of them in order to be able to get notifications from us, and be able to restore your password in case you forget it.',
  'telegram_not_linked_warning': 'this telegram account is not connected to the system, to connect it\'s necessary to go to our <a target="_blank" href="' + process.env.REACT_APP_TELEGRAM_BOT_URL + '">bot</a> and type <span class="text-info">/link</span> command',
  'otp': 'Confirmation code',
  'send_otp': 'Send confirmation code',
  'enter_telegram_otp': 'Enter the code that you will get in your telegram account',
  'invoices': 'Invoices',
  'invoices_do_not_exist': 'Invoices do not exist yet',
  'you_have_due_invoices': 'You have due invoices',
  'download': 'download',
  'paid': 'paid',
  'unpaid': 'unpaid',
  'no_invoices_yet': 'No invoices yet',
  'invoices_created_automatically_hint': 'Invoices are generated automatically and are due at the beginning of each subsequent calendar month',
  'contact_us_in_convenient_way': 'You can contact us in any convenient way for you:',
  'contact_via_telegram': 'Send your message via Telegram anytime!',
  'contact_via_email': 'You can also write to our email address!',
  'contact_via_phone': 'Call us for partnership!',
  'looking_for_a_guide': 'Looking for a guide on how to use this app?',
  'install-now': 'Install',
  'understand': 'Got it',
  'get_to_know_in_detail': 'Find out in detail',
  'this_pwa_can_be_installed': 'Install this web application on your device by placing an app shortcut to your desktop which subsequently allows you access it with a single click',
  'unhandled_error_hint': 'Something went wrong! Try reloading the page, if the problem persists, please, let the administration to know. You can send a screenshot of the page, too',
  'reload': 'Reload',
  'organization_telegram_username_hint': 'username for user, group or channel',
  'enter_sms_otp': 'Enter the code that you will get in an SMS',
  'get_to_know_yet_more': 'Find Out More',
  'user_privacy_warning_for_representative': 'In accordance with the {privacy_policy}, permission to process personal data must be obtained from this {user_type}. The representative of the organization is responsible for this.',
  'user_type_administrator': 'administrator',
  'user_type_service_provider': 'service provider',
  'delete_account_text': 'Delete Account',
  'delete_account_hint': 'Deleting your account will result in the deletion of all information related to your account, including your organizations and all related information, previous bookings and etc. without the possibility of recovery.',
  'delete_my_account_text': 'Delete My Account',
  'enter_password_to_confirm_identity': 'Enter your password to confirm your identity',
  'yes_absolutely': 'Yes, absolutely',
  'changed_my_mind': 'No, I changed my mind',
  'all_account_info_shall_be_deleted': 'Are you sure? All your account information will be deleted and you will not be able to recover it afterwards!',
  'contact_via_phone_work_hours': 'Mon. - Fri., 09:00 - 18:00',
  'who_made_the_booking': 'Who made',
  'the_client_themselves': 'The client themselves',
  'is_deleted': 'deleted',
  'service_type': 'Service type',
  'information': 'Description',
  'price': 'Price',
  'decides_whether_to_show_to_clients': 'Decides whether to display to clients or not',
  'mark_as_read': 'Mark as read',
  'client_acceptance_of_tos': 'By providing personal details, the client expresses their full agreement with the {tos}.',
  'invoice_total_abs': 'In total',
  'invoice_total_bookings': 'Bookings in total',
  'invoice_total_smses': 'SMS in total',
  'select_served_branches': 'Select the branches that are served by this employee',
  'etc': 'And others',
  'get_current_coordinates_short_text': 'get coordinates',
  'get_current_coordinates': 'get coordinates based on current location',
  'welcome': 'Welcome',
  'i_am_a_service_provider': 'I\'m a Service Provider',
  'i_am_a_customer': 'I\'m a Customer',
  'get_help': 'Get Help',
  'not_applicable': 'N/A',
  'enable_push_notifications': 'Subscribe to push notifications',
  'notifications_settings': 'Notifications',
  'browser_does_not_support_notifications': 'This browser does not support notifications.',
  'you_have_denied_push_notifications_permission': 'You denied push notifications permission. Please, grant the permission to subscribe.',
  'you_may_have_to_reinstall_app': 'You may have to reinstall the app to grant the permission again.',
  'install_home_screen_for_push_notifications': 'Adding a web app to home screen is required in order to subscribe to and receive web push notifications on iOS.',
  'need_help': 'Need help?',
  'write_in_telegram': 'write us in telegram',
  'write_in_email': 'send an email request',
  'go_to_help_center': 'go to help center',
  'go_to': 'go',
};

export default en;
