import { connect } from 'react-redux';
import logo from '../assets/img/logo-white.png';
import mobile from '../assets/img/mobile-android.png';
import devices from '../assets/img/devices.png';
import qrWindows from '../assets/img/qr-windows.png';
import qrAndroid from '../assets/img/qr-android.png';
import qrIos from '../assets/img/qr-ios.png';
import qrPwa from '../assets/img/qr-pwa.png';

/**
 * Euroflyer standard dimensions: 210mm x 98 mm = 794px x 374px
 */
function Flyers(props) {
  return (
    <>
      <div className="container bg-secondary">
        <div className="py-5 d-flex align-items-center justify-content-around">
          <div className="flyer text-white bg-primary">
            <div className="flyer-content">
              <div className="w-100 text-center mt-2 mb-4">
                <img src={ logo } className="w-50" alt="" />
              </div>
              <div className="row font-smaller">
                <div className="col-6">
                  Xizmatlarga navbatni onlayn bandlash
                  <hr className="border-white" />
                  <span className="d-block mb-4 small">Qabullar va uchrashuvlarni, bandlash va buyurtmalarni hamda ijara xizmatlarini <strong>youbook.uz</strong> bilan avtomatlashtiring!</span>
                  <h6>Imkoniyatlari:</h6>
                  <ul className="unordered-list small">
                    <li>24/7 rejimidagi onlayn bandlash</li>
                    <li>moslashuvchan ish jadvali kalendari</li>
                    <li>qabul/uchrashuvlar belgilash</li>
                    <li>ijara xizmati</li>
                    <li>joy buyurtmasi</li>
                    <li>xabarnomalar (email, telegram, sms, push)</li>
                    <li>...va boshqalar</li>
                  </ul>
                </div>
                <div className="col-6">
                  Бронирование очереди на услуг онлайн
                  <hr className="border-white" />
                  <span className="d-block mb-4 small">Автоматизируйте приемы и встречи, бронирования и заказы, а также услуги аренды вместе с<br/><strong>youbook.uz</strong>!<br/></span>
                  <h6>Возможности:</h6>
                  <ul className="unordered-list small">
                    <li>бронирование в режиме 24/7</li>
                    <li>гибкий календарь рабочего графика</li>
                    <li>назначение приемов/встреч</li>
                    <li>услуги аренды</li>
                    <li>бронирование мест</li>
                    <li>уведомления (email, telegram, sms, push)</li>
                    <li>...и другие</li>
                  </ul>
                </div>
              </div>
              <div className="subscribe-block font-smaller">
                <img src={ mobile } className="mobile-img" alt="" />
                <ul className="list-unstyled">
                  <li className="small mb-2">Obuna bo'ling / Подпишитесь:</li>
                  <li><i className="fab fa-telegram-plane"></i>&nbsp;&nbsp;youbookuzofficialchannel</li>
                  <li><i className="fab fa-facebook-square"></i>&nbsp;&nbsp;youbookuzofficialpage</li>
                  <li><i className="fab fa-instagram-square"></i>&nbsp;&nbsp;youbook.uz</li>
                  <li className="mt-3"><i className="fas fa-phone"></i>&nbsp;&nbsp;+998 33 778 2 778</li>
                  <li className=""><i className="fas fa-globe"></i>&nbsp;&nbsp;youbook.uz</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="flyer text-white bg-primary">
            <div className="flyer-content">
              <div className="row">
                <div className="col-6">
                  <h6>Kimga mos:</h6>
                  <ul className="unordered-list small">
                    <li>mijozlar bilan ishlovchi tashkilotlarga (shifokorlar, konsultantlar, murabbiylar, sartaroshlar va b.)</li>
                    <li>restoran, kafe, choyxonalar egalariga</li>
                    <li>inventarlar ijarachilariga</li>
                    <li>...va boshqalarga</li>
                  </ul>
                </div>
                <div className="col-6">
                  <h6>Кому подходит:</h6>
                  <ul className="unordered-list small">
                    <li>организациям, работающим с клиентами (врачи, консультанты, тренеры, парикмахеры и т.д.)</li>
                    <li>владельцам ресторанов, кафе, чайных</li>
                    <li>арендаторам инвентарей</li>
                    <li>...и другим</li>
                  </ul>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-12">
                  <img src={ devices } className="w-100" alt="" />
                </div>
                <div className="col-12 mt-4 font-smaller">
                  <ul className="list-unstyled">
                    <li className="small mb-1">
                      Bizga yozing / Пишите нам:
                    </li>
                    <li>
                      <i className="fas fa-headset"></i>&nbsp;&nbsp;youbookuzofficial (telegram)
                    </li>
                    <li>
                      <i className="fas fa-envelope"></i>&nbsp;&nbsp;support@youbook.uz (tech. support)
                    </li>
                    <li>
                      <i className="fas fa-envelope"></i>&nbsp;&nbsp;info@youbook.uz (general)
                    </li>
                  </ul>
                </div>
              </div>
              <div className="row font-smaller mt-1">
                <div className="col-12">
                  Yuklab oling / Скачайте
                </div>
              </div>
              <div className="row qr-codes">
                <div className="col-3 mt-1">
                  <div>
                    <i className="fab fa-windows"></i>&nbsp;&nbsp;windows
                  </div>
                  <div>
                    <img src={ qrWindows } className="w-100" alt="" />
                  </div>
                </div>
                <div className="col-3 mt-1">
                  <div>
                    <i className="fab fa-android"></i>&nbsp;&nbsp;android
                  </div>
                  <div>
                    <img src={ qrAndroid } className="w-100" alt="" />
                  </div>
                </div>
                <div className="col-3 mt-1">
                  <div>
                    <i className="fab fa-apple"></i>&nbsp;&nbsp;ios
                  </div>
                  <div>
                    <img src={ qrIos } className="w-100" alt="" />
                  </div>
                </div>
                <div className="col-3 mt-1">
                  <div>
                    <i className="fab fa-chrome"></i>&nbsp;&nbsp;pwa
                  </div>
                  <div>
                    <img src={ qrPwa } className="w-100" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style>
        {`
          body {
            font-weight: 300;
          }
          .flyer {
            display: block;
            width: 374px;
            height: 794px;
            padding: 2rem;
            background-image: linear-gradient(135deg, #4cd3e3 50%, #3bacf0 50%);
            background-image: -moz-linear-gradient(135deg, #4cd3e3 50%, #3bacf0 50%);
            background-image: -webkit-linear-gradient(135deg, #4cd3e3 50%, #3bacf0 50%);
            background-image: -ms-linear-gradient(135deg, #4cd3e3 50%, #3bacf0 50%);
          }
          .font-smaller {
            font-size: 95%;
          }
          .unordered-list li::before {
            top: 0.25rem;
          }
          .unordered-list li {
            padding-left: 24px;
            line-height: 1.25rem !important;
          }
          .qr-codes {
            font-size: 0.635rem;
          }
          .flyer-content {
            height: 730px;
            overflow: hidden;
            position: relative;
            #border: 1px solid #ffffff;
          }
          .small {
            font-weight: 300;
          }
          span {
            line-height: 1.3rem;
          }
          .mobile-img {
            width: 105px;
            position: absolute;
            top: 558px;
            right: 0px;
          }
          .subscribe-block {
            margin-top: 1.5rem;
          }
        `}
      </style>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    lang: state.lang,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Flyers);
