import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { withRouter } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers/index';
import App from './layouts/App';
import initialState from './store/initialState';
import messages from './lang/messages';
import { logout as logoutAction } from './actions/auth/login';
import {
  helperShowProgressSpinner,
  helperHideProgressSpinner,
} from './helpers/index';
import * as ServiceWorkerRegistration from './ServiceWorkerRegistration';
// import reportWebVitals from './reportWebVitals';

const store = createStore(rootReducer, initialState, applyMiddleware(thunk));


/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */
try {
  window.Popper = require('popper.js').default;
  window.$ = window.jQuery = require('jquery');
  require('bootstrap');
  /**
   * We'll load the axios HTTP library which allows us to easily issue requests
   * to our Laravel back-end. This library automatically handles sending the
   * CSRF token as a header based on the value of the "XSRF" token cookie.
   */
  window.axios = require('axios');
  window.axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  window.axios.defaults.headers.common['Content-Type'] = 'application/json';
  window.axios.defaults.headers.common['Accept'] = 'application/json';
  window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
  window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + window.localStorage.getItem('user-token');

  window.axios.interceptors.request.use(
    function (config) {
      /**
       * If user token is expired, automatically log the user out.
       */
      const userTokenExpiryDatetime = window.localStorage.getItem('user-token-expiry');
      if (userTokenExpiryDatetime && window.moment(userTokenExpiryDatetime).isBefore(window.moment())) {
        store.dispatch(logoutAction(true));
      }

      const userToken = window.localStorage.getItem('user-token');
      if (userToken) {
        config.headers['Authorization'] = 'Bearer ' + userToken;
      }
      const userLang = window.localStorage.getItem('user-lang');
      if (userLang) {
        config.headers['X-User-Lang'] = userLang;
      }
      helperShowProgressSpinner();
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  window.axios.interceptors.response.use(
    function (response) {
      setTimeout(function () {
        helperHideProgressSpinner();
      }, 250);

      if (response && response.data && response.data.flashes) {
        store.dispatch({
          type: 'SET_FORM_FLASHES',
          payload: response.data.flashes,
        });
        // automatically clear all form flashes after a few seconds
        setTimeout(function () {
          store.dispatch({
            type: 'SET_FORM_FLASHES',
            payload: {},
          });
        }, 7000);
      }

      return response;
    },
    function (error) {
      setTimeout(function () {
        helperHideProgressSpinner();
      }, 250);

      if (error.response && error.response.data && error.response.data.errors) {
        store.dispatch({
          type: 'SET_FORM_ERRORS',
          payload: error.response.data.errors,
        });
        // automatically clear all form errors after a few seconds
        setTimeout(function () {
          store.dispatch({
            type: 'SET_FORM_ERRORS',
            payload: {},
          });
        }, 7000);
      }

      const lang = store.getState().lang;
      if (error.message === 'Network Error') {
        window.alertModal(messages[lang]['no_internet_connection']);
      } else if (error.response.status === 401) {
        if (error.response.config.url !== '/auth/login') {
          window.alertModal(messages[lang]['you_are_not_authenticated_please_login']);
          setTimeout(function () {
            store.dispatch(logoutAction(true));
          }, 4000);
        }
      } else if (error.response.status === 403) {
        window.alertModal(messages[lang]['no_sufficient_rights_to_perform_action']);
      } else if (error.response.status === 409 || error.response.status === 422) {
        if (error.response.data) {
          console.log(error.response.data);
        }
      } else if (error.response.status === 429) {
        window.alertModal(messages[lang]['too_many_requests']);
      } else {
        if (error.response.data.error) {
          window.alertModal(error.response.data.error);
        } else if (error.response.data.message) {
          window.alertModal(error.response.data.message);
        } else {
          window.alertModal(error);
        }
      }

      return Promise.reject(error);
    }
  );

  window.moment = require('moment');
  require('moment/locale/ru');
  require('moment/locale/uz-latn');
  window.momentTimezone = require('moment-timezone');

  require('select2/dist/js/select2.min');
  require('select2/dist/js/i18n/ru');
  window.jQuery.fn.select2.amd.define('select2/i18n/uz', [], function () {
    // Uzbek
    return {
      errorLoading: function () {
        return 'Natijalar yuklanmadi';
      },
      inputTooLong: function (args) {
        var overChars = args.input.length - args.maximum;
        var message = 'Iltimos ' + overChars + ' ta belgini o\'chiring';
        return message;
      },
      inputTooShort: function (args) {
        var remainingChars = args.minimum - args.input.length;
        var message = 'Iltimos ' + remainingChars + ' ta yoki ko\'proq belgi kiriting';
        return message;
      },
      loadingMore: function () {
        return 'Ko\'proq natijalar yuklanmoqda…';
      },
      maximumSelected: function (args) {
        var message = 'Faqat ' + args.maximum + ' ta tanlovni amalga oshirishingiz mumkin';
        return message;
      },
      noResults: function () {
        return 'Natijalar topilmadi';
      },
      searching: function () {
        return 'Izlanmoqda…';
      }
    };
  });
} catch (e) {}


/**
 * Load additional plugins
 */
import('bootstrap-datetime-picker/js/bootstrap-datetimepicker.min.js').then((datetimepicker) => {
  window.$.fn.datetimepicker.dates['ru'] = {
    days: ["Воскресенье", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота", "Воскресенье"],
    daysShort: ["Вск", "Пнд", "Втр", "Срд", "Чтв", "Птн", "Суб", "Вск"],
    daysMin: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"],
    months: ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"],
    monthsShort: ["Янв", "Фев", "Мар", "Апр", "Май", "Июн", "Июл", "Авг", "Сен", "Окт", "Ноя", "Дек"],
    today: "Сегодня",
    suffix: [],
    meridiem: []
  };
  window.$.fn.datetimepicker.dates['uz'] = {
    days: ["Yakshanba", "Dushanba", "Seshanba", "Chorshanba", "Payshanba", "Juma", "Shanba", "Yakshanba"],
    daysShort: ["Yak", "Dush", "Sesh", "Chor", "Pay", "Jum", "Shan", "Yak"],
    daysMin: ["Ya", "Du", "Se", "Cho", "Pa", "Ju", "Sha", "Ya"],
    months: ["Yanvar", "Fevral", "Mart", "Aprel", "May", "Iyun", "Iyul", "Avgust", "Sentabr", "Oktabr", "Noyabr", "Dekabr"],
    monthsShort: ["Yan", "Fev", "Mar", "Apr", "May", "Iyun", "Iyul", "Avg", "Sen", "Okt", "Noy", "Dek"],
    today: "Bugun",
    suffix: [],
    meridiem: []
  };
}).catch((error) => console.error(error));


// Add the following code if you want the name of the file appear on select
window.jQuery(document).on("change", ".custom-file-input", function () {
  var fileName = window.jQuery(this).val().split("\\").pop();
  window.jQuery(this).siblings(".custom-file-label").addClass("selected").html(fileName);
});


// pretty print json
window.prettyPrint = function (obj) {
  return <pre>{ JSON.stringify(obj, null, 2) }</pre>;
};


window.alertModal = function (txt, modalId = null, contentType = 'text') {
  modalId = modalId ? ('#' + modalId) : '#alert-modal';
  const alertModal = window.jQuery(modalId);
  const isShown = (alertModal.hasClass('in') || alertModal.hasClass('show'));
  if (! isShown) {
    if (txt) {
      const modalBody = alertModal.find('.modal-body').eq(0);
      if (contentType === 'html') {
        modalBody.html(txt);
      } else {
        modalBody.text(txt);
      }
    }
    alertModal.modal('show');
  }
};


/**
 * @link: https://reacttraining.com/react-router/web/api/withRouter
 * Create a new component that is "connected" (to borrow redux terminology) to the router.
 */
const AppWithRouter = withRouter(App);

// to access updated match, location and history props ALL the application must be wrapped in BrowserRouter
ReactDOM.render(
  <StrictMode>
    <Provider store={ store }>
      <BrowserRouter>
        <AppWithRouter />
      </BrowserRouter>
    </Provider>
  </StrictMode>,
  document.getElementById('root')
);

ServiceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
